import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  autoUpdate,
  flip,
  offset as fuiOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import type { DropdownContextValue, DropdownProps } from './Dropdown.types';

const DropdownContext = createContext<DropdownContextValue>(
  {} as DropdownContextValue
);

export const useDropdownContext = () => useContext(DropdownContext);

export const Dropdown = (props: DropdownProps) => {
  const {
    open: originalOpen,
    handler: originalHandler,
    placement,
    dismiss,
    offset,
    children,
  } = props;

  const [internalOpen, setInternalOpen] = useState(false);
  const open = originalOpen || internalOpen;
  const handler = originalHandler || setInternalOpen;
  const {
    x,
    y,
    strategy,
    update,
    context,
    refs: { reference, floating, setReference, setFloating },
  } = useFloating({
    open,
    onOpenChange: handler,
    middleware: [fuiOffset(offset), flip(), shift()],
    placement,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, dismiss),
  ]);

  useEffect(() => {
    if (reference.current && floating.current && open) {
      return autoUpdate(reference.current, floating.current, update);
    }
  }, [open, update, reference, floating]);

  const valueContext = useMemo(
    (): DropdownContextValue => ({
      open,
      strategy,
      x,
      y,
      context,
      reference: setReference,
      floating: setFloating,
      getReferenceProps,
      getFloatingProps,
    }),
    [
      open,
      strategy,
      x,
      y,
      context,
      setReference,
      setFloating,
      getFloatingProps,
      getReferenceProps,
    ]
  );

  return (
    <DropdownContext.Provider value={valueContext}>
      {children}
    </DropdownContext.Provider>
  );
};

Dropdown.displayName = 'Dropdown';
