import { storageKey } from '../constans';
import { storage } from './storage';
import { ValidToken } from '../types/utils';
import { ParamsToStore } from '../components/elements/table';

export const validAuth = (): ValidToken => {
  const userToken = storage.getItem(storageKey.authToken);
  if (!userToken) {
    return {
      isValid: false,
      message: 'Harap login terlebih dahulu',
    };
  }
  try {
    const token = JSON.parse(atob(userToken.split('.')[1]));
    if (token.exp * 1000 < Date.now()) {
      return {
        isValid: false,
        message: 'Waktu login sudah habis, silahkan login kembali',
      };
    }
    return {
      isValid: true,
      message: 'User valid',
    };
  } catch (e) {
    return {
      isValid: false,
      message: 'Harap login terlebih dahulu',
    };
  }
};

export const removeStoredLastCurrentPage = () => {
  localStorage.removeItem('tableParams');
};

export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key];
}

export const setTableLastParams = (el: ParamsToStore) => {
  const dataToStore = JSON.stringify(el);
  localStorage.setItem('tableParams', dataToStore);
};

export const getTableLastCurrentParams = (location: string) => {
  const storedData = {
    currentPage: 1,
    limit: 10,
    search: '',
    pathname: '',
    query: undefined,
    sorting: [],
  };
  const tempItem = localStorage.getItem('tableParams');
  if (tempItem) {
    const item = tempItem ? JSON.parse(tempItem) : null;
    if (item.pathname === location) {
      storedData.currentPage = item.currentPage;
      storedData.limit = item.limit;
      storedData.search = item.search;
      storedData.pathname = item.pathname;
      storedData.query = item.query;
      storedData.sorting = item.sorting;
      return storedData;
    }
  }
  return storedData;
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime());
};
