import axios from '../../../libs/axios';
import { useQuery } from '@tanstack/react-query';

import type { ExtractFnReturnType } from 'src/libs/react-query';
import type { DataConfig, UseConfigDataOptions } from '../types';
import type { BaseAll } from '../../../types/apis';

const getConfigData = async ({
  params,
  signal,
}: BaseAll): Promise<DataConfig> => {
  return await axios
    .get('/clusters/install-config', {
      params,
      signal,
    })
    .then((res) => res.data);
};

const useConfigData = ({ config, params }: UseConfigDataOptions) => {
  return useQuery<ExtractFnReturnType<typeof getConfigData>>({
    ...config,
    queryKey: [
      'config-data',
      ...(params ? Object.values(params).filter(Boolean) : []),
    ],
    queryFn: ({ signal }) => getConfigData({ params, signal }),
  });
};

export { getConfigData, useConfigData };
