import type { SelectValue } from '../types/components';

export const getValueKey = (path: string, obj: any) => {
  const pathList = path.split('.');
  return pathList.reduce((prev, curr) => prev?.[curr], obj);
};

export const getSelectValue = (
  list: any[] | undefined,
  value: any,
  keyValue?: string,
  keyLabel?: string
): SelectValue => {
  if (!list)
    return {
      value: '',
      label: '',
    };

  if (keyValue && keyLabel) {
    const data = list.find((elm) => getValueKey(keyValue, elm) === value);
    return {
      value: getValueKey(keyValue, data) || '',
      label: getValueKey(keyLabel, data) || '',
    };
  } else {
    const getValue = list.find((elm) => elm.value === value);
    return getValue ? getValue : { value: '', label: '' };
  }
};

export const getTotalValue = (row: any[], keyData: string) => {
  if (row.length === 0) return 0;
  if (!keyData || keyData === '') return 0;
  return row.reduce(
    (acc: number, current: any) => acc + Number(current[keyData]),
    0
  );
};

const getExponentValue = (xValue: number, yValue: number) => {
  return Math.floor(Math.log(xValue) / Math.log(yValue));
};

export const formatFileSize = (bytes: number) => {
  if (!isFinite(bytes)) return '0B';
  if (bytes === 0) return '0B';
  const i = getExponentValue(bytes, 1000);
  const unitSize = ['B', 'KB', 'MB', 'GB', 'TB'][i];
  const size = (bytes / Math.pow(1000, i)).toFixed(2);

  return `${size}${unitSize}`;
};

export const sortArrayData = (arr: any[], key: string) => {
  return Object.values(arr).sort((a, b) => a[key] - b[key]);
};

export const groupArrayData = (
  xs: any[],
  key: string
): Record<string, any[]> => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const sliceText = (text: string, maxLength: number = 50) => {
  if (!text) return '';
  return `${text.slice(0, maxLength)}${text.length > maxLength ? '...' : ''}`;
};

export const formatBase64 = (text: string) => {
  if (!text) return '';
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (base64regex.test(text)) return atob(text);
  return text;
};

export const uppercaseFirstLetter = (words: string) => {
  return words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};
