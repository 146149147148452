import { FormikHelpers } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  getErrorMessage,
  // getMessage
} from '../../../utils/apis';

import { useRegister as useRegisterService } from '../services';
import { RegisterFormDTO } from '../types';
import notification from '../../../stores/notification';
import { useEffect } from 'react';

const useRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (!queryParameters.get('email') || !queryParameters.get('token')) {
      notification.error('Register URL not valid');
      navigate('../');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formValue: RegisterFormDTO = {
    email: queryParameters.get('email') as string,
    username: '',
    password: '',
    confirm_password: '',
    display_name: '',
    token: queryParameters.get('token') as string,
  };

  const register = useRegisterService();
  const handleSubmit = async (
    values: RegisterFormDTO,
    formikHelpers: FormikHelpers<RegisterFormDTO>
  ) => {
    await register
      .mutateAsync({
        data: values,
      })
      .then(() => {
        notification.success('Registered Successfully!');
        navigate(location?.state?.redirect || '../');
      })
      .catch((err) => {
        notification.error(getErrorMessage(err));
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  return {
    formValue,
    handleSubmit,
  };
};

export default useRegister;
