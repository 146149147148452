import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { BaseById } from 'src/types/apis';
import type { DeleteClusterDTO, DeleteClusterOptions } from '../types';

const deleteCluster = async ({
  id,
  signal,
}: BaseById): Promise<AxiosResponse<DeleteClusterDTO>> => {
  return await axios.delete(`/clusters/${id}`, {
    signal,
  });
};

const useDeleteCluster = (props?: DeleteClusterOptions) => {
  return useMutation({
    mutationFn: deleteCluster,
    ...props?.config,
  });
};

export { deleteCluster, useDeleteCluster };
