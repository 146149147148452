import { z } from 'zod';

const loginForm = z.object({
  username: z.string({
    required_error: 'Username must be filled in',
    invalid_type_error: 'Username must contain characters',
  }),
  password: z.string({
    required_error: 'Password must be filled in',
    invalid_type_error: 'Password must contain characters',
  }),
});

const registerForm = z.object({
  display_name: z.string({
    required_error: 'Display Name must be filled in',
    invalid_type_error: 'Display Name must contain characters',
  }),
  username: z.string({
    required_error: 'Username must be filled in',
    invalid_type_error: 'Username must contain characters',
  }).refine((val) => /^[a-z0-9-]+$/g.test(val), "Name can only include lowercase alphanumeric and dashes"),
  email: z
    .string({
      required_error: 'Email must be filled in',
      invalid_type_error: 'Email must contain characters',
    })
    .email({
      message: 'Invalid Email',
    }),
  password: z.string({
    required_error: 'Password must be filled in',
    invalid_type_error: 'Password must contain characters',
  }),
  confirm_password: z.string({
    required_error: 'Confirm Password must be filled in',
    invalid_type_error: 'Confirm Password must contain characters',
  }),
}).refine((data) => data.password === data.confirm_password, {
  message: "Passwords don't match",
  path: ["confirm_password"],
});;

const forgotForm = z.object({
  email: z
    .string({
      required_error: 'Email must be filled in',
      invalid_type_error: 'Email must contain characters',
    })
    .email({
      message: 'Invalid Email',
    }),
});;

const resetForm = z.object({
  new_password: z.string({
    required_error: 'Password must be filled in',
    invalid_type_error: 'Password must contain characters',
  }),
  confirm_password: z.string({
    required_error: 'Confirm Password must be filled in',
    invalid_type_error: 'Confirm Password must contain characters',
  }),
}).refine((data) => data.new_password === data.confirm_password, {
  message: "Passwords don't match",
  path: ["confirm_password"],
});;


export { loginForm, registerForm, forgotForm, resetForm };
