import axios from 'src/libs/axios';
import { useQuery } from '@tanstack/react-query';

import type { ExtractFnReturnType } from 'src/libs/react-query';
import type { BaseProcessForm } from 'src/types/apis';
import type {
  StatusInstallClusterDTO,
  UseStatusInstallClusterOptions,
} from '../types';

const getStatusInstallCluster = async ({
  id,
  idProcess,
  params,
  signal,
}: BaseProcessForm): Promise<StatusInstallClusterDTO> => {
  return await axios
    .get(`/clusters/${id}/process-status/${idProcess}`, {
      params,
      signal,
    })
    .then((res) => (res.data ? { data: res.data } : res.data));
};

const useStatusInstallCluster = ({
  id,
  idProcess,
  config,
  params,
}: UseStatusInstallClusterOptions) => {
  return useQuery<ExtractFnReturnType<typeof getStatusInstallCluster>>({
    ...config,
    queryKey: [
      'status-install-cluster',
      id,
      idProcess,
      ...(params ? Object.values(params).filter(Boolean) : []),
    ],
    queryFn: ({ signal }) =>
      getStatusInstallCluster({ id, idProcess, params, signal }),
  });
};

export { getStatusInstallCluster, useStatusInstallCluster };
