import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { BaseCreateForm } from 'src/types/apis';
import type {
  CreateClusterMetaDataDTO,
  CreateClusterMetaDataOptions,
  ClusterMetaDataFormDTO,
} from '../types';

const createClusterMetaData = async ({
  data,
  signal,
}: BaseCreateForm<ClusterMetaDataFormDTO>): Promise<
  AxiosResponse<CreateClusterMetaDataDTO>
> => {
  return await axios.post('/clusters', data, {
    signal,
  });
};

const useCreateClusterMetaData = (props?: CreateClusterMetaDataOptions) => {
  return useMutation({
    mutationFn: createClusterMetaData,
    ...props?.config,
  });
};

export { createClusterMetaData, useCreateClusterMetaData };
