import React from 'react';
import clsx from 'clsx';

interface PaperCardProps {
  children: React.ReactNode;
  shadow?: boolean;
  classname?: string;
}

export const PaperCard: React.FC<PaperCardProps> = ({
  children,
  shadow,
  classname,
}) => {
  return (
    <div
      className={clsx(
        'rounded-lg p-6 border border-gray-200',
        shadow &&
        'shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]',
        classname && classname
      )}
    >
      {children}
    </div>
  );
};

PaperCard.displayName = 'PaperCard';
