import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { removeStoredLastCurrentPage } from '../../../utils/base';
import { TypeDashboardForm } from 'src/types/dashboard';
import { useClusterForm } from 'src/features/clusters/hooks';
import { HiOutlineChevronDown } from 'react-icons/hi';
import clsx from 'clsx';

import type { NavigationItemSubMenuProps } from './Navigation.types';
import type { DashboardFormProps } from 'src/types/dashboard';

export const NavigationSubMenu = forwardRef<
  HTMLDivElement,
  NavigationItemSubMenuProps
>((props, ref) => {
  const {
    contentData,
    className,
    collapsed,
    menuIndex,
    formattedAccess,
    ...rest
  } = props;

  const contentRef = useRef<HTMLUListElement>(null);

  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let timer: NodeJS.Timer;
  const duration = 300;

  const handleSubmenuClicked = () => {
    if (collapsed) {
      setIsOpen((prev) => !prev);
    }
  };

  const getType: DashboardFormProps = {
    type: TypeDashboardForm.create,
  };
  const { handleWindowClose } = useClusterForm(getType);

  useEffect(() => {
    clearTimeout(timer);
    if (mounted) {
      if (isOpen) {
        const target = contentRef?.current;
        if (target) {
          target.style.display = 'block';
          target.style.overflow = 'hidden';
          target.style.height = 'auto';
          const height = target.offsetHeight;
          target.style.height = '0px';
          /* eslint-disable @typescript-eslint/no-unused-expressions */
          target.offsetHeight;
          target.style.height = `${height}px`;

          /* eslint-disable react-hooks/exhaustive-deps */
          timer = setTimeout(() => {
            target.style.overflow = 'auto';
            target.style.height = 'auto';
          }, duration);
        }
      } else {
        const target = contentRef?.current;
        if (target) {
          target.style.overflow = 'hidden';
          target.style.height = `${target.offsetHeight}px`;
          target.style.height = '0px';

          timer = setTimeout(() => {
            target.style.overflow = 'auto';
            target.style.display = 'none';
          }, duration);
        }
      }
    }
    return () => clearTimeout(timer);
  }, [isOpen, contentRef]);

  useEffect(() => {
    if (!collapsed) {
      setIsOpen(false);
    }
    setIsOpen(false);
  }, [collapsed]);

  useEffect(() => {
    setMounted(true);
    setIsOpen(contentData?.isActive || false);
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(className, contentData?.isActive && 'active-child')}
      {...rest}
    >
      <div className='group relative px-2'>
        <button
          type='button'
          onClick={handleSubmenuClicked}
          aria-hidden='true'
          className='menu'
        >
          <div className={clsx('flex', !collapsed && 'w-full justify-center')}>
            {contentData?.icon && (
              <contentData.icon
                className={clsx('h-6 w-6', collapsed && 'mr-2')}
              />
            )}
            <span
              className={clsx(
                collapsed ? 'opacity-100' : 'w-0 overflow-hidden opacity-0',
                'whitespace-nowrap'
              )}
            >
              {contentData.title}
            </span>
          </div>
          {collapsed && (
            <HiOutlineChevronDown
              className={clsx(
                'h-5 w-5 transition-transform duration-300',
                isOpen && 'rotate-180'
              )}
            />
          )}
        </button>
        {!collapsed && (
          <div
            className={clsx(
              menuIndex > 8 ? 'bottom-0' : 'top-[9rem]',
              'dropdown-menu fixed left-[75px] hidden z-[100] h-auto group-hover:block'
            )}
          >
            <ul className='mini-scrollbar max-h-[calc(100vh_-_120px)] min-w-[15rem] overflow-y-auto bg-white shadow-md p-3 rounded'>
              {contentData?.children &&
                contentData?.children.map((val) => {
                  const hasAccess = (val?.access || []).reduce((acc, value) => {
                    if (formattedAccess?.includes(value) && !acc) {
                      acc = true;
                    }
                    return acc;
                  }, false);
                  if (!hasAccess) return '';
                  return (
                    <li key={val.to}>
                      <Link
                        to={val.to}
                        onClick={(e) => {
                          e.preventDefault();
                          removeStoredLastCurrentPage();
                          handleWindowClose(val.to);
                        }}
                        className={clsx(
                          'menu',
                          val?.isActive && 'active',
                          '!pl-4'
                        )}
                        tabIndex={isOpen ? 0 : -1}
                      >
                        {val?.icon && <val.icon className='mr-2 h-6 w-6' />}
                        <span className='whitespace-nowrap'>{val.title}</span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
      <ul
        ref={contentRef}
        className='h-0 overflow-hidden transition-all duration-300'
      >
        {contentData?.children &&
          contentData?.children.map((val) => {
            const hasAccess = (val?.access || []).reduce((acc, value) => {
              if (formattedAccess?.includes(value) && !acc) {
                acc = true;
              }
              return acc;
            }, false);
            if (!hasAccess) return '';
            return (
              <li key={val.to} className='mx-2'>
                <Link
                  to={val.to}
                  onClick={(e) => {
                    e.preventDefault();
                    removeStoredLastCurrentPage();
                    handleWindowClose(val.to);
                  }}
                  className={clsx('menu', val?.isActive && 'active')}
                  tabIndex={isOpen ? 0 : -1}
                >
                  {val?.icon && <val.icon className='mr-2 h-6 w-6' />}
                  <span>{val.title}</span>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
});

NavigationSubMenu.displayName = 'NavigationSubMenu';
