import { useEffect, useState } from 'react';

const useTimer = () => {
  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(false);

  // state to store time
  const [time, setTime] = useState(0);

  // Minutes calculation
  var minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  var seconds = Math.floor((time % 6000) / 100);

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 100), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Method to start and stop timer
  const toggleTimer = () => {
    setIsRunning(!isRunning);
  };

  // Method to reset timer back to 0
  const reset = () => {
    setTime(0);
  };

  return { time, toggleTimer, reset, minutes, seconds };
};

export default useTimer;
