import { NotificationPosition } from '../components/notifications';
import { LocalKey } from '../utils/storage';

import type { ConfirmationDismissType } from '../components/confirmation';
import type { ModalDismissType } from '../components/elements';

const API_BASE_URL = (window as Window).__RUNTIME_CONFIG__
  .REACT_APP_API_BASE_URL;

const KIBANA_BASE_URL = (window as Window).__RUNTIME_CONFIG__
  .REACT_APP_KIBANA_BASE_URL;

const storageKey = {
  authToken: new LocalKey<string | null | undefined>('auth-token', ''),
};

const DateFormat = {
  base: 'Y-MM-dd',
  detail: 'dd MMM Y HH:mm:ss',
  table: 'MM-dd-Y',
};

const usersRole = {
  sysadmin: 'sysadmin',
  customer: 'customer',
};

const moduleName = {
  dashboard: 'dashboard',
  profile: 'profile',
  clusters: 'clusters',
};

const roleAction = {
  create: 'create',
  read: 'read',
  update: 'update',
};

const roleGroup = {
  C: [roleAction.create],
  R: [roleAction.read],
  U: [roleAction.update],
  CRU: [roleAction.create, roleAction.read, roleAction.update],
};

const publicRoleMatiks = [moduleName.profile];

const roleMatriks = {
  [usersRole.sysadmin]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.clusters]: roleGroup.CRU,
  },
  [usersRole.customer]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.clusters]: roleGroup.CRU,
  },
};

const componentConfig = {
  notification: {
    interval: 2500,
    position: NotificationPosition.topRight,
  },
  confirmation: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ConfirmationDismissType,
    confirm: {
      title: 'Are you sure?',
      message: 'If you continue, the data you have entered will be lost!',
    },
    alert: {
      title: 'Success',
      message: 'Content created successfully!',
    },
  },
  modal: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ModalDismissType,
  },
};

const chartColors = [
  '#ffffd9',
  '#edf8b1',
  '#c7e9b4',
  '#7fcdbb',
  '#41b6c4',
  '#1d91c0',
  '#225ea8',
  '#253494',
  '#081d58',
];

export {
  storageKey,
  API_BASE_URL,
  roleMatriks,
  roleAction,
  moduleName,
  publicRoleMatiks,
  componentConfig,
  DateFormat,
  KIBANA_BASE_URL,
  chartColors,
};
