import { roleMatriks, roleAction } from '../constans';
import { createState } from '../libs/state';
import { AuthTypes } from '../features/auth';

const { setState, useState } = createState({
  auth: null as AuthTypes.UserAuthState,
});

export const useAuth = () => useState('auth');

export const setAuth = (val: AuthTypes.UserMeState) => {
  if (!val) return;

  const role = roleMatriks[val.data.org_id];
  const navigation = Object.keys({}).reduce((acc, val) => {
    if (role[val].includes(roleAction.read)) {
      acc.push(val);
    }
    return acc;
  }, [] as string[]);
  const permission =
    val.orgs[0].permissions.reduce((filtered: string[], option) => {
      const readPermission = option.split(':');
      if (readPermission[0] === 'read') {
        const permission = readPermission[1];
        if (filtered.indexOf(permission) === -1) filtered.push(permission);
      }
      return filtered;
    }, []) || [];

  setState('auth', {
    user: val,
    navigation,
    role,
    permission,
  });
};
