import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { BaseUpdateForm } from 'src/types/apis';
import type { InstallClusterDTO, InstallClusterOptions } from '../types';

const installCluster = async ({
  id,
  signal,
}: BaseUpdateForm<null>): Promise<AxiosResponse<InstallClusterDTO>> => {
  return await axios.post(`/clusters/${id}/install`, null, {
    signal,
  });
};

const useInstallCluster = (props?: InstallClusterOptions) => {
  return useMutation({
    mutationFn: installCluster,
    ...props?.config,
  });
};

export { installCluster, useInstallCluster };
