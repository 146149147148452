import { Route, RouteObject, Routes } from 'react-router-dom';

import { AuthProvider } from '../providers';

import { Login, Register } from '../features/auth/component';

import { lazyImport } from '../utils/performance';

const { AuthRoutes } = lazyImport(
  () => import('../features/auth'),
  'AuthRoutes'
);

export const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <Routes>
        <Route element={<AuthProvider />}>
          <Route path='' element={<Login />} />
          <Route path='register' element={<Register />} />
        </Route>
      </Routes>
    ),
  },
  {
    path: '*',
    element: <AuthRoutes />,
  },
];
