import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { BaseUpdateForm } from 'src/types/apis';
import type { ValidationClusterDTO, ValidationClusterOptions } from '../types';

const validationCluster = async ({
  id,
  signal,
}: BaseUpdateForm<null>): Promise<AxiosResponse<ValidationClusterDTO>> => {
  return await axios.post(`/clusters/${id}/validate`, null, {
    signal,
  });
};

const useValidationCluster = (props?: ValidationClusterOptions) => {
  return useMutation({
    mutationFn: validationCluster,
    ...props?.config,
  });
};

export { validationCluster, useValidationCluster };
