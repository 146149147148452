import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { BaseAll } from 'src/types/apis';
import type {
  IKubernetesDTO,
  KubernetesDTO,
  UseKubernetesOptions,
} from '../types';

const getKubernetes = async ({
  params,
  signal,
}: BaseAll): Promise<KubernetesDTO> => {
  return await axios
    .get('/kubernetes', {
      params,
      signal,
    })
    .then((res) => {
      const dataRes = res.data.data;
      const kubeData: IKubernetesDTO[] = dataRes.map((e: any) => {
        return { kube_id: e?.kube?.kube_id, kube_name: e?.kube?.kube_name };
      });
      const result: KubernetesDTO = {
        token: '',
        message: '',
        data: kubeData,
      };
      return result;
    });
};

const useKubernetes = (props?: UseKubernetesOptions) => {
  return useMutation({
    mutationFn: getKubernetes,
    ...props?.config,
  });
};

export { getKubernetes, useKubernetes };
