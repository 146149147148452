import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { BaseCreateForm } from '../../../types/apis';

import axios from '../../../libs/axios';

import { ResetDTO, ResetFormDTO, ResetOptions } from '../types';

const reset = async ({
  data,
  signal,
}: BaseCreateForm<ResetFormDTO>): Promise<AxiosResponse<ResetDTO>> => {
  return await axios.post('/auth/reset-password/' + data.token, data, {
    signal,
  });
};

const useReset = (props?: ResetOptions) => {
  return useMutation({
    mutationFn: reset,
    ...props?.config,
  });
};

export { reset, useReset };
