import {
  HiOutlineCircleStack,
  HiOutlineHome,
  HiShieldCheck,
} from 'react-icons/hi2';
import {
  HiOutlineUser,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
  HiServer,
} from 'react-icons/hi';
import { BiAnchor, BiCodeBlock, BiDetail } from 'react-icons/bi';
import { FaUserShield } from 'react-icons/fa';

const navigation = [
  {
    title: 'Dashboard',
    to: '/home',
    icon: HiOutlineHome,
    access: ['home'],
  },
  {
    title: 'Clusters',
    to: '/clusters',
    icon: HiOutlineCircleStack,
    access: ['clusters'],
  },
  {
    title: 'Admin',
    to: '/admin',
    icon: HiOutlineUserCircle,
    access: [
      'users',
      'teams',
      'roles',
      'roleassignments',
      'environments',
      'kubernetes',
      'serviceaccounts',
      'auditlog',
    ],
    children: [
      {
        title: 'Users',
        to: '/users',
        icon: HiOutlineUser,
        access: ['users'],
      },
      {
        title: 'Teams',
        to: '/teams',
        icon: HiOutlineUserGroup,
        access: ['teams'],
      },
      {
        title: 'Roles',
        to: '/roles',
        icon: HiShieldCheck,
        access: ['roles'],
      },
      {
        title: 'Role Assignments',
        to: '/role-assignments',
        icon: FaUserShield,
        access: ['roleassignments'],
      },
      {
        title: 'Environments',
        to: '/environments',
        icon: HiServer,
        access: ['environments'],
      },
      {
        title: 'Kubernetes',
        to: '/kubernetes',
        icon: BiAnchor,
        access: ['kubernetes'],
      },
      {
        title: 'Service Accounts',
        to: '/service-accounts',
        icon: BiCodeBlock,
        access: ['serviceaccounts'],
      },
      {
        title: 'Audit Logs',
        to: '/audit-log',
        icon: BiDetail,
        access: ['auditlog'],
      },
    ],
  },
];

export { navigation };
