import { createContext, useCallback, useMemo, useState } from 'react';

import type { BaseLayoutProps } from '../../../types/components';

interface ClusterContextType {
  title: string;
  installed: boolean;
  minReplica: number;
}

interface ClusterDetailContextType {
  clusterData: ClusterContextType;
  refreshData: any;
  isLoading: boolean;
  handleSetClusterData: (value: ClusterContextType) => void;
  handleSetRefresh: (value: any) => void;
  handleRefresh: () => void;
}

const initialClusterContext = {
  title: '',
  installed: false,
  minReplica: 1,
};

export const ClusterDetailContext = createContext<ClusterDetailContextType>({
  clusterData: initialClusterContext,
  refreshData: undefined,
  isLoading: false,
  handleSetClusterData: () => {},
  handleSetRefresh: () => {},
  handleRefresh: () => {},
});

const ClusterDetailContextProvider: React.FC<BaseLayoutProps> = ({
  children,
}) => {
  const [clusterData, setClusterData] = useState<ClusterContextType>(
    initialClusterContext
  );
  const [refreshData, setRefreshData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSetClusterData = useCallback((value: ClusterContextType) => {
    setClusterData(value);
  }, []);

  const handleSetRefresh = useCallback((value: any) => {
    setRefreshData(value ? () => () => value() : undefined);
  }, []);

  const handleRefresh = useCallback(async () => {
    if (refreshData) {
      setIsLoading(true);
      await refreshData();
      setIsLoading(false);
    }
  }, [refreshData]);

  const contextValue = useMemo(
    () => ({
      clusterData,
      refreshData,
      isLoading,
      handleSetClusterData,
      handleSetRefresh,
      handleRefresh,
    }),
    [
      clusterData,
      refreshData,
      isLoading,
      handleSetClusterData,
      handleSetRefresh,
      handleRefresh,
    ]
  );

  return (
    <ClusterDetailContext.Provider value={contextValue}>
      {children}
    </ClusterDetailContext.Provider>
  );
};

export default ClusterDetailContextProvider;
