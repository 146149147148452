import { useContext, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiOutlineMenu } from 'react-icons/hi';
import { BaseLayoutProps } from '../../../types/components';
import { storageKey } from '../../../constans';
import { useAuth } from '../../../stores/auth';
import { storage } from '../../../utils/storage';
import { navigation } from './data';
import { DashboardContext } from './ContextProvider';
import {
  Button,
  Dropdown,
  DropdownHandler,
  DropdownMenu,
  Navigation,
} from '../../elements';
import clsx from 'clsx';
import notification from 'src/stores/notification';

const DashboardLayout: React.FC<BaseLayoutProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [auth] = useAuth();

  const { collapseSideMenu, handleCollapseSideMenu } =
    useContext(DashboardContext);

  const formattedAccess = useMemo((): string[] => {
    return (
      auth?.user?.orgs[0].permissions.reduce((filtered: string[], option) => {
        const readPermission = option.split(':');
        if (readPermission[0] === 'read') filtered.push(readPermission[1]);
        return filtered;
      }, []) || []
    );
  }, [auth?.user?.orgs]);

  const handleLogout = () => {
    storage.removeItem(storageKey.authToken);
    notification.success('Berhasil logout!');
    navigate('/login');
    window.location.reload();
  };

  if (location.pathname !== '/dashboard/clusters/create') {
    localStorage.removeItem('idCluster');
    localStorage.removeItem('processID');
    localStorage.removeItem('step');
    localStorage.removeItem('status-install');
  }

  return (
    <div className='min-h-screen w-full' {...props}>
      <nav className='fixed z-30 w-full border-b border-gray-200 bg-white py-2'>
        <div className='px-3 py-1.5 lg:px-5 lg:pl-3'>
          <div className='flex items-center justify-between gap-3'>
            <div className='flex items-center justify-start gap-3'>
              <Button
                type='button'
                className='icon-only hover:bg-gray-100'
                onClick={() => handleCollapseSideMenu(!collapseSideMenu)}
              >
                <HiOutlineMenu className='h-7 w-7' />
              </Button>
              <Link to='/dashboard/home' className='ml-0 lg:ml-4'>
                <p className='text-3xl font-bold text-blue-800'>FLUENTUM</p>
              </Link>
            </div>
            <Dropdown placement='bottom-end'>
              <DropdownHandler>
                <div
                  className='flex cursor-pointer items-center justify-center'
                  role='button'
                  tabIndex={0}
                >
                  {auth?.user?.user.display_name && (
                    <>
                      <div className='flex flex-row'>
                        <div className='text-gray-600 '>
                          {auth?.user?.user.display_name}
                        </div>
                      </div>
                      <span className='mx-2 h-6 w-0.5 bg-gray-200 xs:block' />
                    </>
                  )}
                  <img
                    src='https://img.icons8.com/color/48/user.png'
                    className='h-10 w-10 rounded-full object-cover '
                    alt='Avatar'
                  />
                  {/* <Avatar
                    src={defaultAvatar}
                    alt={`Avatar ${auth?.user?.nama_lengkap}`}
                    size='xl'
                  /> */}
                </div>
              </DropdownHandler>
              <DropdownMenu>
                <div className='dropdown-menu-container w-22 mt-6'>
                  <Link to='/dashboard/profile' className='dropdown-menu'>
                    Profile
                  </Link>
                  <Link
                    to='/dashboard/profile/change_password'
                    className='dropdown-menu'
                  >
                    Change Password
                  </Link>
                  <span className='devide' />
                  <button
                    type='button'
                    onClick={handleLogout}
                    className='dropdown-menu'
                  >
                    Logout
                  </button>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </nav>
      <div className='relative flex overflow-hidden'>
        {/*<aside className='fixed top-0 left-0 z-10 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700 transform-none'>*/}
        <aside
          className={clsx(
            'mini-scrollbar h-screen fixed top-0 left-0 z-10 flex flex-col bg-white',
            'py-4 pt-20 transition-all duration-200 overflow-y-auto shadow',
            collapseSideMenu ? 'w-56 lg:w-64' : 'w-0 lg:w-20'
          )}
        >
          <Navigation
            currentLocation={location.pathname}
            access={formattedAccess}
            currentPath='/dashboard'
            items={navigation}
            collapsed={collapseSideMenu}
          />
        </aside>
        <div
          className={clsx(
            'relative ml-0 h-full w-full overflow-y-auto bg-gray-100 transition-all duration-200',
            collapseSideMenu ? 'ml-56 lg:ml-64' : 'ml-0 lg:ml-20'
          )}
        >
          <main className='h-screen pt-16'>{props.children}</main>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
