import React from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyImport } from '../utils/performance';
import { ClusterDetailProvider, DashboardProvider } from '../providers/index';

const { DashboardRoutes } = lazyImport(
  () => import('../features/dashboard'),
  'DashboardRoutes'
);

const { ProfileRoutes } = lazyImport(
  () => import('../features/profile'),
  'ProfileRoutes'
);

const { ClustersRoutes } = lazyImport(
  () => import('../features/clusters'),
  'ClustersRoutes'
);

const { ClusterDetailRoutes } = lazyImport(
  () => import('../features/cluster-detail'),
  'ClusterDetailRoutes'
);

const { UsersRoutes } = lazyImport(
  () => import('../features/management-user/users'),
  'UsersRoutes'
);

const { TeamsRoutes } = lazyImport(
  () => import('../features/management-user/teams'),
  'TeamsRoutes'
);

const { RolesRoutes } = lazyImport(
  () => import('../features/management-user/roles'),
  'RolesRoutes'
);

const { RoleAssignmentsRoutes } = lazyImport(
  () => import('../features/management-user/role-assignments'),
  'RoleAssignmentsRoutes'
);

const { EnvironmentsRoutes } = lazyImport(
  () => import('../features/management-user/environments'),
  'EnvironmentsRoutes'
);

const { KubernetesRoutes } = lazyImport(
  () => import('../features/management-user/kubernetes'),
  'KubernetesRoutes'
);

const { ServiceAccountRoutes } = lazyImport(
  () => import('../features/management-user/service-account'),
  'ServiceAccountRoutes'
);

const { LogsRoutes } = lazyImport(
  () => import('../features/management-user/logs'),
  'LogsRoutes'
);

export const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <DashboardProvider />,
    children: [
      {
        path: 'home/*',
        element: <DashboardRoutes />,
      },
      {
        path: 'profile/*',
        element: <ProfileRoutes />,
      },
      {
        path: 'clusters/*',
        element: <ClustersRoutes />,
      },
      {
        path: 'clusters/view',
        element: <ClusterDetailProvider />,
        children: [
          {
            path: '*',
            element: <ClusterDetailRoutes />,
          },
        ],
      },
      {
        path: 'admin/*',
        children: [
          { path: 'users/*', element: <UsersRoutes /> },
          { path: 'teams/*', element: <TeamsRoutes /> },
          { path: 'roles/*', element: <RolesRoutes /> },
          { path: 'role-assignments/*', element: <RoleAssignmentsRoutes /> },
          { path: 'environments/*', element: <EnvironmentsRoutes /> },
          { path: 'kubernetes/*', element: <KubernetesRoutes /> },
          { path: 'service-accounts/*', element: <ServiceAccountRoutes /> },
          { path: 'logs/*', element: <LogsRoutes /> },
        ],
      },
    ],
  },
];
