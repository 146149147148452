import { useAuth } from 'src/stores/auth';

export const useCreateAccess = (createType: string, type?: string) => {
  const [auth] = useAuth();

  const userPerms = auth?.user?.orgs[0].permissions;
  let hasAccess = userPerms?.includes(`create:${createType}`);
  if (!hasAccess) hasAccess = userPerms?.includes(`create:${createType}:*`);
  if (!hasAccess) hasAccess = userPerms?.includes('create:clusters:*');
  if (!hasAccess && type)
    hasAccess = userPerms?.includes(`create:clusters:*:${type}`);
  return hasAccess;
};

export const useUpdateAccess = (updateType: string, type?: string) => {
  const [auth] = useAuth();

  const userPerms = auth?.user?.orgs[0].permissions;
  let hasAccess = userPerms?.includes(`update:${updateType}`);
  if (!hasAccess) hasAccess = userPerms?.includes(`update:${updateType}:*`);
  if (!hasAccess) hasAccess = userPerms?.includes('update:clusters:*');
  if (!hasAccess && type)
    hasAccess = userPerms?.includes(`update:clusters:*:${type}`);
  return hasAccess;
};

export const useDeleteAccess = (deleteType: string, type?: string) => {
  const [auth] = useAuth();

  const userPerms = auth?.user?.orgs[0].permissions;
  let hasAccess = userPerms?.includes(`delete:${deleteType}`);
  if (!hasAccess) hasAccess = userPerms?.includes(`delete:${deleteType}:*`);
  if (!hasAccess) hasAccess = userPerms?.includes('delete:clusters:*');
  if (!hasAccess && type)
    hasAccess = userPerms?.includes(`delete:clusters:*:${type}`);
  return hasAccess;
};
