import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { BaseCreateForm } from '../../../types/apis';

import axios from '../../../libs/axios';

import { RegisterDTO, RegisterFormDTO, RegisterOptions } from '../types';

const register = async ({
  data,
  signal,
}: BaseCreateForm<RegisterFormDTO>): Promise<AxiosResponse<RegisterDTO>> => {
  return await axios.post('/auth/register/' + data.token, data, {
    signal,
  });
};

const useRegister = (props?: RegisterOptions) => {
  return useMutation({
    mutationFn: register,
    ...props?.config,
  });
};

export { register, useRegister };
