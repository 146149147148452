import { useLocation, useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../utils/apis';
import { storage } from '../../../utils/storage';
import { storageKey } from '../../../constans';
import { useLogin as useLoginService } from '../services';
import notification from '../../../stores/notification';

import type { FormikHelpers } from 'formik';
import type { LoginFormDTO } from '../types';

const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const formValue: LoginFormDTO = {
    username: '',
    password: '',
  };

  const login = useLoginService();
  const handleSubmit = async (
    values: LoginFormDTO,
    formikHelpers: FormikHelpers<LoginFormDTO>
  ) => {
    await login
      .mutateAsync({
        data: values,
      })
      .then((res) => {
        notification.success('Successful login');
        const accessToken = res?.data?.token;
        if (accessToken) {
          storage.setItem(storageKey.authToken, `Bearer ${accessToken}`);
          navigate(location?.state?.redirect || '/dashboard/home');
        } else {
          notification.error('There was an error in token access!');
        }
      })
      .catch((err) => {
        notification.error(getErrorMessage(err));
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  return {
    formValue,
    handleSubmit,
  };
};

export default useLogin;
