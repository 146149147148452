import { FormikHelpers } from 'formik';
import { useLocation, useNavigate, } from 'react-router-dom';

import {
  getErrorMessage,
  // getMessage
} from '../../../utils/apis';

import { useForgot as useForgotService } from '../services';
import { ForgotFormDTO } from '../types';
import notification from '../../../stores/notification';

const useForgot = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const formValue: ForgotFormDTO = {
    email: '',
  };

  const forgot = useForgotService();
  const handleSubmit = async (
    values: ForgotFormDTO,
    formikHelpers: FormikHelpers<ForgotFormDTO>
  ) => {
    await forgot
      .mutateAsync({
        data: values,
      })
      .then(() => {
        notification.success('Please check your email for your password reset instructions');
        navigate(location?.state?.redirect || '../');
      })
      .catch((err) => {
        notification.error(getErrorMessage(err));
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  return {
    formValue,
    handleSubmit,
  };
};

export default useForgot;
