import { FormikHelpers } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  getErrorMessage,
  // getMessage
} from '../../../utils/apis';

import { useReset as useResetService } from '../services';
import { ResetFormDTO } from '../types';
import notification from '../../../stores/notification';
import { useEffect } from 'react';

const useReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (!queryParameters.get('token')) {
      notification.error('Reset Password URL not valid');
      navigate('../');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formValue: ResetFormDTO = {
    new_password: '',
    confirm_password: '',
    token: queryParameters.get('token') as string,
  };

  const reset = useResetService();
  const handleSubmit = async (
    values: ResetFormDTO,
    formikHelpers: FormikHelpers<ResetFormDTO>
  ) => {
    await reset
      .mutateAsync({
        data: values,
      })
      .then(() => {
        notification.success('Password Reset Successfully!');
        navigate(location?.state?.redirect || '../');
      })
      .catch((err) => {
        notification.error(getErrorMessage(err));
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  return {
    formValue,
    handleSubmit,
  };
};

export default useReset;
