import axios from '../../../libs/axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { BaseAll } from '../../../types/apis';
import type { ExtractFnReturnType } from '../../../libs/react-query';
import type {
  ClusterDTO,
  ClusterStatusDTO,
  UseClusterDropdownOptions,
  UseClusterOptions,
  UseClusterStatusOptions,
} from '../types';

const getCluster = async ({ params, signal }: BaseAll): Promise<ClusterDTO> => {
  return await axios
    .get('/clusters', {
      params,
      signal,
    })
    .then((res) => res.data);
};

const useCluster = ({ config, params }: UseClusterOptions = {}) => {
  return useQuery<ExtractFnReturnType<typeof getCluster>>({
    ...config,
    queryKey: [
      'cluster',
      ...(params ? Object.values(params).filter(Boolean) : []),
    ],
    queryFn: ({ signal }) => getCluster({ params, signal }),
  });
};

const getClusterStatus = async ({
  params,
  signal,
}: BaseAll): Promise<ClusterStatusDTO> => {
  return await axios
    .get('/clusters/kafka-status', {
      params,
      signal,
    })
    .then((res) => res.data);
};

const useClusterStatus = ({ config, params }: UseClusterStatusOptions = {}) => {
  return useQuery<ExtractFnReturnType<typeof getClusterStatus>>({
    ...config,
    queryKey: [
      'cluster-status',
      ...(params ? Object.values(params).filter(Boolean) : []),
    ],
    queryFn: ({ signal }) => getClusterStatus({ params, signal }),
  });
};

const useClusterDropdown = (props?: UseClusterDropdownOptions) => {
  return useMutation({
    mutationFn: getCluster,
    ...props?.config,
  });
};

export {
  getCluster,
  useCluster,
  getClusterStatus,
  useClusterStatus,
  useClusterDropdown,
};
