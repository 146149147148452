
import clsx from 'clsx';
import { useEmbedLoader } from '../../../hooks/useEmbedLoader'

interface IEmbedLoader {
  styles?: string
}

const EmbedLoader = (props: IEmbedLoader) => {
  const { loading } = useEmbedLoader()
  return (
    <> {
      loading && (
        <div className={clsx(
          'absolute flex w-full h-[1000px] justify-center items-center bg-white rounded',
          props?.styles
        )}>
          <p className='text-3xl font-bold text-blue-800'>FLUENTUM</p>
        </div>
      )
    }
    </>
  );
};

export default EmbedLoader;
