import React, { Suspense } from 'react';
import { LoadingScreen } from './components/elements/loading-screen';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './libs/react-query';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import GlobalAuthProvider from './providers/GlobalAuth';
import { Confirmation } from './components/confirmation';
import { NotificationContainer } from './components/notifications';
import { StepsProvider } from 'react-step-builder';

function App() {
  return (
    <Suspense fallback={<LoadingScreen reason='Initial App' />}>
      <QueryClientProvider client={queryClient}>
        <NotificationContainer />
        <Confirmation />
        <BrowserRouter>
          <GlobalAuthProvider>
            <StepsProvider>
              <AppRoutes />
            </StepsProvider>
          </GlobalAuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
