import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface DatePickersProps {
  startDate: Date;
  handleOnChange: (date: Date) => void;
}

export const DatePickers: React.FC<DatePickersProps> = ({
  startDate,
  handleOnChange,
}) => {
  return (
    <DatePicker
      showTimeSelect
      dateFormat='dd MMM yyyy, HH:mm'
      timeFormat='HH:mm'
      className='form-field md outline'
      selected={startDate}
      onChange={(date) => handleOnChange(date as Date)}
      onKeyDown={(event) => event.preventDefault()}
    />
  );
};
