import { Form, Formik, FormikProps } from 'formik';
import { useRegister } from '../hooks';
// import { useState } from "react";
import { validationSchema } from '../../../utils/validation';
import { registerForm } from '../schema';
import { RegisterFormDTO } from '../types';
import bannerImage from 'src/assets/images/3.jpeg';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from '../../../components/elements';

const Register = () => {
  const { formValue, handleSubmit } = useRegister();
  //   const [showPassword, setShowPassword] = useState<boolean>(false);
  //   const toggleShowPassword = () => {
  //     setShowPassword((prev: any) => !prev);
  //   };
  return (
    <div className='relative flex min-h-screen'>
      <div className='flex min-w-0 flex-auto flex-row items-center justify-center bg-white md:items-start md:justify-start'>
        <div className='w-auto bg-white p-8 sm:rounded-lg md:flex md:h-full md:items-center  md:justify-center md:rounded-none md:p-10 lg:p-14 xl:w-2/5'>
          <div className='w-full max-w-md space-y-8 md:space-y-14'>
            <h1 className='text-5xl font-bold text-gray-800'>
              Register your account
            </h1>
            <div>
              <Formik
                initialValues={formValue}
                validationSchema={validationSchema(registerForm)}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                  dirty,
                  isSubmitting,
                }: FormikProps<RegisterFormDTO>) => {
                  return (
                    <Form>
                      <div className='space-y-6'>
                        <FormControl errorKey='email'>
                          <FormLabel>Email</FormLabel>
                          <Input
                            variant='outline'
                            color='primary'
                            name='email'
                            type='text'
                            value={values.email}
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl errorKey='username'>
                          <FormLabel>Username</FormLabel>
                          <Input
                            variant='outline'
                            color='primary'
                            name='username'
                            placeholder='Type Username here...'
                            type='text'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl errorKey='display_name'>
                          <FormLabel>Display Name</FormLabel>
                          <Input
                            variant='outline'
                            color='primary'
                            name='display_name'
                            placeholder='Type Display Name here...'
                            type='text'
                            value={values.display_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl errorKey='password'>
                          <FormLabel>Password</FormLabel>
                          <Input
                            color='primary'
                            variant='outline'
                            name='password'
                            type={'password'}
                            placeholder='Type password here...'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl errorKey='confirm_password'>
                          <FormLabel>Confirm Password</FormLabel>
                          <Input
                            color='primary'
                            variant='outline'
                            name='confirm_password'
                            type={'password'}
                            placeholder='Confirm your password here...'
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <Button
                          type='submit'
                          variant='solid'
                          color='primary'
                          isFluid
                          disabled={!(isValid && dirty)}
                          isLoading={isSubmitting}
                        >
                          Register
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className='relative hidden h-full flex-auto items-center justify-center overflow-hidden bg-blue-500 p-10 text-white sm:w-1/2 md:flex xl:w-3/5'>
          <img
            src={bannerImage}
            className='absolute h-full w-full object-cover'
            alt='Banner'
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
