/* eslint-disable no-useless-rename */
import { format } from 'date-fns';
import { BreadcrumbsData } from '../components/elements';
import { SelectValue } from '../types/components';
import {
  TypeDashboardForm,
  DashboardFormType,
  DashboardBaseTypeDetail,
  BuildBreadcrumbs,
} from '../types/dashboard';
import { CallbackValuesMapper } from '../types/utils';
import { isValidDate } from './base';

export const getDashboardType = (
  type: DashboardFormType
): DashboardBaseTypeDetail => {
  return {
    isEdit: type === TypeDashboardForm.edit,
    isCreate: type === TypeDashboardForm.create,
    isView: type === TypeDashboardForm.view,
    isDeviasi: type === TypeDashboardForm.deviasi,
    isAmandemen: type === TypeDashboardForm.amandemen,
  };
};

export const getDashboardTypeName = (
  type: DashboardBaseTypeDetail,
  prefix?: string
) => {
  const baseTitle = type.isCreate
    ? 'Create'
    : type.isEdit
    ? 'Edit'
    : type.isDeviasi
    ? 'Deviasi'
    : type.isAmandemen
    ? 'Amandemen'
    : 'Detail';
  return baseTitle + (prefix ? ` ${prefix}` : '');
};

export const routeConcat = (...routes: string[]) => {
  const route = routes.join('/').replace(/\/\//g, '/');
  return route.startsWith('/') ? route.slice(1) : route;
};

export const getDashboardPath = (
  type: DashboardFormType,
  currentPath: string
) => {
  const route = currentPath.slice(1).split('/');
  const position = route.indexOf(type);
  const end = position === -1 ? route.length - 1 : position;

  return {
    home: `/${route[0]}`,
    parent: `/${routeConcat(...route.slice(0, end))}`,
  };
};

export const buildBreadcrumbs = ({
  type,
  parentTitle,
  currentTitle,
  currentPath,
}: BuildBreadcrumbs): BreadcrumbsData[] => {
  const route = getDashboardPath(type, currentPath.pathname);
  return [
    {
      to: route.home,
      content: 'Home',
    },
    {
      to: route.parent,
      content: parentTitle,
    },
    {
      to: currentPath.pathname,
      content: currentTitle,
    },
  ];
};

export const objectValue = (obj?: SelectValue): string => {
  return obj?.value || '';
};

export const objectValueNull = (obj?: SelectValue): string | null => {
  return obj?.value || null;
};

export const valuesMapper = <T, U>(
  values: T,
  callback: CallbackValuesMapper<T, U>
): U => {
  const response = { ...values } as T;
  return callback(response);
};

export const dateFormat = (
  value: string | undefined,
  dateFormat: string,
): string => {
  if (!value) return '';
  const newDate = new Date(value)
  if (!isValidDate(newDate)) return ''
  return format(newDate, dateFormat)
}

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)).toString(), 10)

  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i]
}

export const formatToPercent = (value: number) => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value / 100)
}

export const formatNetworkSpeed = (speed: number) => {
  if (speed === 0) return '0 bps'
  const k = 1000
  const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps']
  const i = Math.floor(Math.log(speed) / Math.log(k))

  if (i === -1) {
    return (speed / Math.pow(k, i)).toFixed(0) + ' ' + sizes[0]
  }
  const formattedValue = (speed / Math.pow(k, i)).toFixed(speed % 1 === 0 ? 0 : 2);
  return formattedValue + ' ' + sizes[i];
}
