import { HiOutlinePlus } from 'react-icons/hi';
import { Breadcrumbs } from '../breadcrumbs';
import { ButtonLink } from '../button';
import { useCreateAccess } from '../../../utils/permission';
import clsx from 'clsx';

import type { BaseLayoutProps } from './BaseLayout.types';

export const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const {
    title,
    breadcrumbTitle,
    children,
    customActions,
    hideActions = false,
    hideAddButton,
    customClass,
    idProp,
  } = props;

  const hasCreateAccess = useCreateAccess(idProp || '');

  return (
    <div className='wrapper-dashboard'>
      <Breadcrumbs
        items={[
          {
            to: '/dashboard/home',
            content: 'home',
          },
          {
            to: '',
            content: breadcrumbTitle || title,
          },
        ]}
      />
      <div
        className={clsx(
          'w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8',
          customClass
        )}
      >
        {!hideActions && (
          <div className='flex w-full justify-between mb-[1rem]'>
            <h1 className='title'>{title}</h1>
            {!hideActions && (
              <div className='flex'>
                {customActions}
                {!hideAddButton && hasCreateAccess && (
                  <div className='ml-1'>
                    <ButtonLink
                      to='create'
                      variant='solid'
                      color='primary'
                      className='ml-1'
                      id={'add-button-' + idProp}
                      onClick={() => {
                        localStorage.removeItem('status-install');
                      }}
                      leftIcon={<HiOutlinePlus className='mr-1 h-5 w-5' />}
                    >
                      Add
                    </ButtonLink>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
