import { useRef } from 'react';
import { moduleName } from '../../../constans';
import { useAccess } from '../../../hooks/useAccess';
import { TableHandle } from '../../../components/elements/table';
import { useDeleteCluster } from '../services';
import confirmation from 'src/stores/confirmation';
import { getErrorMessage } from 'src/utils/apis';

const useClusterList = () => {
  const access = useAccess(moduleName.clusters);
  const tableRef = useRef<TableHandle>(null);

  const deleteCluster = useDeleteCluster();
  const handleDelete = async (idx: string) => {
    confirmation.confirm({
      title: 'Are you sure?',
      message: 'If you continue, the cluster data will be deleted!',
      onConfirm: async (close, setLoading) => {
        setLoading(true);
        await deleteCluster
          .mutateAsync({ id: idx })
          .then(() => {
            close();
            confirmation.alert({
              icon: 'success',
              title: 'Success!',
              message: 'Successfully delete cluster data',
              onReject: (close) => {
                tableRef.current?.update();
                close();
              },
            });
          })
          .catch((err) => {
            confirmation.alert({
              icon: 'error',
              title: 'Oops!',
              message: getErrorMessage(err),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  return {
    handleDelete,
    access,
    tableRef,
  };
};

export default useClusterList;
