import { UseAccess } from '../types/hooks';

import { publicRoleMatiks, roleAction } from '../constans';

import { useAuth } from '../stores/auth';

export const useAccess = (moduleName: string): UseAccess => {
  const [auth] = useAuth();

  const hasAccess = (access: string): boolean => {
    if (publicRoleMatiks.includes(access)) return true;
    return (auth?.role?.[moduleName] || []).includes(access);
  };
  const hasCreate = (): boolean => {
    return hasAccess(roleAction.create);
  };
  return {
    hasAccess,
    hasCreate,
  };
};
