import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { forwardRef } from 'react';
import { HiHome, HiOutlineChevronRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import { BreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(
  (props, ref) => {
    const { items, separator, ...rest } = props;

    return (
      <nav
        className='breadcrumb-wrapper'
        aria-label='Breadcrumb'
        ref={ref}
        {...rest}
      >
        <ol className='breadcrumb'>
          {items &&
            items.map((val, idx) => {
              const isLast = idx === items.length - 1;
              const Element = !isLast ? Link : 'span';
              return (
                <li key={nanoid()}>
                  <div className='flex items-center'>
                    {idx !== 0 &&
                      (separator || (
                        <HiOutlineChevronRight className='h-5 w-5 text-gray-500' />
                      ))}
                    <Element
                      to={val.to}
                      className={clsx(
                        'capitalize breadcrumb-item',
                        isLast && 'active'
                      )}
                    >
                      {val.content.toLowerCase() === 'home' ? (
                        <HiHome className='h-5 w-5' />
                      ) : (
                        val.content
                      )}
                    </Element>
                  </div>
                </li>
              );
            })}
        </ol>
      </nav>
    );
  }
);

Breadcrumbs.displayName = 'Breadcrumbs';
