import axios from 'src/libs/axios';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { BaseUpdateForm } from 'src/types/apis';
import type { UninstallClusterDTO, UninstallClusterOptions } from '../types';

const uninstallCluster = async ({
    id,
    signal,
}: BaseUpdateForm<null>): Promise<AxiosResponse<UninstallClusterDTO>> => {
    return await axios.post(`/clusters/${id}/cancel-installation?=`, null, {
        signal,
    });
};

const useUninstallCluster = (props?: UninstallClusterOptions) => {
    return useMutation({
        mutationFn: uninstallCluster,
        ...props?.config,
    });
};

export { uninstallCluster, useUninstallCluster }