import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {
  ClusterDetailContextProvider,
  ClusterDetailLayout,
} from '../components/layouts';
import { LoadingScreen } from '../components/elements/loading-screen';

const ClusterDetailProvider: React.FC = () => {
  return (
    <ClusterDetailContextProvider>
      <ClusterDetailLayout>
        <Suspense fallback={<LoadingScreen reason='Get Cluster Component' />}>
          <Outlet />
        </Suspense>
      </ClusterDetailLayout>
    </ClusterDetailContextProvider>
  );
};

export default ClusterDetailProvider;
