import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSteps } from 'react-step-builder';
import {
  buildBreadcrumbs,
  getDashboardType,
  getDashboardTypeName,
  objectValue,
  valuesMapper,
} from 'src/utils/dashboard';
import {
  useConfigData,
  useCreateClusterMetaData,
  useInstallCluster,
  useStatusInstallCluster,
  useUninstallCluster,
  useValidationCluster,
} from '../services';
import { useAuth } from 'src/stores/auth';
import { setClusterId } from 'src/stores/cluster';
import { getErrorMessage } from 'src/utils/apis';
import confirmation from 'src/stores/confirmation';
import notification from 'src/stores/notification';

import type { FormikHelpers } from 'formik';
import type { DashboardFormProps } from 'src/types/dashboard';
import type {
  ClusterMetaDataFormDTO,
  ClusterMetaDataForms,
  DataConfig,
  IClusterMetaDataForms,
} from '../types';

const flattenObject = (obj: any) => {
  return Object.keys(obj).reduce(function (r, k) {
    return r.concat(obj[k]);
  }, []);
};

const groupArrayData = (
  xs: any[] | undefined,
  key: string
): Record<string, any[]> => {
  return xs?.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const useClusterForm = ({ type }: DashboardFormProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getType = getDashboardType(type);
  const title = getDashboardTypeName(getType, 'Cluster');
  const [auth] = useAuth();
  const { id: formId } = useParams();

  const canLeave = useRef(true);

  const [dataProcessInstall, setDataProcessInstall] = useState<any>([]);
  const [dataValidation, setDataValidation] = useState<any>([]);
  const [dataConfig, setDataConfig] = useState<DataConfig | undefined>({
    install_config: [],
  });
  const [statusInstall, setStatusInstall] = useState<boolean>(false);
  const [statusValidate, setStatusValidate] = useState<boolean>(false);
  const [installProgress, setInstallProgress] = useState<string>('');
  const [validateProgress, setValidateProgress] = useState<string>('');
  const [idProcess, setIdProcess] = useState(localStorage.getItem('processID'));
  const [formValueMetaData, setFormValueMetaData] =
    useState<ClusterMetaDataForms>({
      content: {
        organization_id: auth?.user?.data.org_id as number,
        cluster_name: '',
        display_name: '',
        install_config: '',
      },
    });

  const { prev, next, jump } = useSteps();

  const breadcrumbData = buildBreadcrumbs({
    currentPath: location,
    parentTitle: 'Clusters',
    currentTitle: title,
    type,
  });

  const parentPath = breadcrumbData[1].to;
  const stepNumber = Number(localStorage.getItem('step'));
  const idCluster = localStorage.getItem('idCluster');

  const getConfigData = useConfigData({});
  const createClusterMetaData = useCreateClusterMetaData();
  const installKafka = useInstallCluster();
  const uninstallKafka = useUninstallCluster();
  const validationCluster = useValidationCluster();
  const getStatusInstallKafka = useStatusInstallCluster({
    id: idCluster || '',
    idProcess: idProcess || '',
    config: {
      enabled: idCluster !== null && idProcess !== null,
      refetchInterval: !(statusInstall || statusValidate) ? false : 5000,
    },
  });

  useEffect(() => {
    if (localStorage.getItem('idCluster')) {
      jump(stepNumber);
    }
  }, [jump, location?.state?.redirect, navigate, stepNumber]);

  useEffect(() => {
    if (!getConfigData) return;
    const { data } = getConfigData;
    setDataConfig(data);
  }, [getConfigData]);

  useEffect(() => {
    setFormValueMetaData((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        install_config: groupArrayData(dataConfig?.install_config, 'category'),
      },
    }));
  }, [dataConfig?.install_config]);

  useEffect(() => {
    if (localStorage.getItem('status-install')) {
      const dataLS = JSON.parse(
        localStorage.getItem('status-install') as string
      );
      setDataProcessInstall(dataLS);
    }
  }, []);

  useEffect(() => {
    if (!getStatusInstallKafka.data) return;
    const { messages, overall_status } = getStatusInstallKafka.data.data.data;
    if (stepNumber === 3) handleStatusInstall(messages, overall_status);
    else if (stepNumber === 4) handleStatusValidation(messages, overall_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatusInstallKafka.data]);

  useEffect(() => {
    return () => {
      if (installProgress === 'Completed') {
        setInstallProgress('');
        localStorage.removeItem('processID');
        localStorage.removeItem('status-install');
      }
      if (validateProgress === 'Success') {
        setValidateProgress('');
        localStorage.removeItem('step');
        localStorage.removeItem('status-validate');
        localStorage.removeItem('idCluster');
      }
    };
  }, [installProgress, validateProgress]);

  const handleWindowClose = (contentData: string) => {
    if ((stepNumber === 3 && idCluster) || idProcess) {
      confirmation.confirm({
        title: 'Are you sure?',
        message:
          'If you leave this page, you cannot return. If you want to leave, you can see the progress in the status cluster.',
        onConfirm: (close) => {
          canLeave.current = true;
          if (canLeave.current) {
            handleUninstall()
            localStorage.removeItem('idCluster');
            localStorage.removeItem('processID');
            localStorage.removeItem('step');
            localStorage.removeItem('status-install');
            navigate('/dashboard/clusters');
            close();
          } else {
            navigate('/dashboard/clusters/create');
          }
        },
      });
    } else {
      navigate(contentData);
    }
  };

  const handleStatusInstall = (messages: string[], overall_status: string) => {
    setInstallProgress(overall_status);
    setDataProcessInstall(messages);

    if (overall_status === 'InProgress') {
      setStatusInstall(true);
      localStorage.setItem('status-install', JSON.stringify(messages));
    }
    if (overall_status === 'Failed') {
      setStatusInstall(false);
      localStorage.setItem('status-install', JSON.stringify(messages));
      localStorage.removeItem('processID');
      localStorage.removeItem('step');
    }
    if (overall_status === 'Success') {
      if (!statusInstall) return;
      setStatusInstall(false);
      confirmation.alert({
        icon: 'success',
        title: 'Success!',
        message: 'Install Kafka successfully!',
        onReject: (close) => {
          close();
          setDataProcessInstall([]);
          localStorage.removeItem('processID');
          localStorage.removeItem('status-install');
          localStorage.setItem('step', '4');
          next();
        },
      });
    }
  };

  const handleStatusValidation = (
    messages: string[],
    overall_status: string
  ) => {
    setValidateProgress(overall_status);
    setDataValidation(messages);

    if (overall_status === 'InProgress') {
      setStatusValidate(true);
      localStorage.setItem('status-validate', JSON.stringify(messages));
    }
    if (overall_status === 'Failed') {
      setStatusValidate(false);
      localStorage.setItem('status-validate', JSON.stringify(messages));
      localStorage.removeItem('processID');
      localStorage.removeItem('step');
    }
    if (overall_status === 'Success') {
      if (!statusValidate) return;
      setStatusValidate(false);
      confirmation.alert({
        icon: 'success',
        title: 'Success!',
        message: 'Kafka validate successfully!',
        onReject: (close) => {
          close();
          navigate('/dashboard/clusters');
          localStorage.removeItem('step');
          localStorage.removeItem('status-validate');
          localStorage.removeItem('idCluster');
        },
      });
    }
  };

  const handleSubmitMetaData = async (
    values: IClusterMetaDataForms,
    formikHelpers: FormikHelpers<IClusterMetaDataForms>
  ) => {
    const newValues = valuesMapper<
      IClusterMetaDataForms,
      ClusterMetaDataFormDTO
    >(values, (val) => {
      return {
        organization_id: val.organization_id,
        environment_id: objectValue(val.environment_id),
        kube_id: objectValue(val.kube_id),
        cluster_name: val.cluster_name,
        display_name: val.display_name,
        install_config: flattenObject(val.install_config),
      };
    });
    // eslint-disable-next-line no-unreachable
    await createClusterMetaData
      .mutateAsync({
        data: newValues,
      })
      .then((res) => {
        confirmation.alert({
          icon: 'success',
          title: 'Success!',
          message: 'Metadata cluster created successfully!',
          onReject: (close) => {
            const idData = (res.data.data as unknown as { cluster_id: number })
              ?.cluster_id;
            setClusterId(idData.toString());
            localStorage.setItem('idCluster', idData.toString());
            localStorage.setItem('step', '3');
            close();
            next();
          },
        });
      })
      .catch((err) => {
        confirmation.alert({
          icon: 'error',
          title: 'Oops!',
          message: getErrorMessage(err),
        });
      });
    // eslint-disable-next-line no-unreachable
    formikHelpers.setSubmitting(false);
  };

  const handleInstall = async () => {
    const idData = localStorage.getItem('idCluster');
    await installKafka
      .mutateAsync({
        id: `${idData}`,
        data: null,
      })
      .then((res: any) => {
        const { message, processID } = res.data;
        notification.info(message);
        localStorage.setItem('processID', processID);
        setIdProcess(processID);
      });
  };

  const handleUninstall = async () => {
    const idData = localStorage.getItem('idCluster');
    await uninstallKafka
      .mutateAsync({
        id: `${idData}`,
        data: null,
      })
      .then((res: any) => {
        console.log(res);
      });
  };

  const handleValidation = async () => {
    const idData = localStorage.getItem('idCluster');
    await validationCluster
      .mutateAsync({
        id: `${idData}`,
        data: null,
      })
      .then((res: any) => {
        const { message, processID } = res.data;
        notification.info(message);
        localStorage.setItem('processID', processID);
        setIdProcess(processID);
      });
  };

  return {
    breadcrumbData,
    getType,
    formId,
    navigate,
    title,
    parentPath,
    formValueMetaData,
    setFormValueMetaData,
    next,
    prev,
    dataProcessInstall,
    statusInstall,
    dataValidation,
    statusValidate,
    dataConfig,
    installProgress,
    validateProgress,
    handleSubmitMetaData,
    handleInstall,
    handleUninstall,
    handleValidation,
    handleWindowClose,
  };
};

export default useClusterForm;
