import { Fragment, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { validAuth } from '../utils/base';
import notification from 'src/stores/notification';

let hasNavigateBack = false;
const AuthProvider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hasValidAuth = validAuth();
    if (hasValidAuth.isValid && !hasNavigateBack) {
      hasNavigateBack = true;
      if (location.pathname === '/login') {
        notification.error('You are already logged in!');
      }
      navigate('/dashboard/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default AuthProvider;
