import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { BaseCreateForm } from '../../../types/apis';

import axios from '../../../libs/axios';

import { ForgotDTO, ForgotFormDTO, ForgotOptions } from '../types';

const forgot = async ({
  data,
}: BaseCreateForm<ForgotFormDTO>): Promise<AxiosResponse<ForgotDTO>> => {
  return await axios.get('/auth/reset-password/' + data.email);
};

const useForgot = (props?: ForgotOptions) => {
  return useMutation({
    mutationFn: forgot,
    ...props?.config,
  });
};

export { forgot, useForgot };
