import { ClusterTypes } from "src/features/clusters";
import { createState } from "src/libs/state";

const {setState, useState} = createState({
    cluster_id: null as ClusterTypes.ClusterState
})

export const useClusterId = () => useState('cluster_id')
export const setClusterId = (val: string) => {
    setState("cluster_id", {
        cluster_id: val
    })
}