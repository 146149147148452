import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from '../../../components/elements';
import { useLogin } from '../hooks';
import { validationSchema } from '../../../utils/validation';
import { loginForm } from '../schema';
import bannerImage from 'src/assets/images/3.jpeg';

import type { FormikProps } from 'formik';
import type { LoginFormDTO } from '../types';

const Login = () => {
  const { formValue, handleSubmit } = useLogin();

  return (
    <div className='relative flex min-h-screen'>
      <div className='flex min-w-0 flex-auto flex-row items-center justify-center bg-white md:items-start md:justify-start'>
        <div className='w-auto bg-white p-8 sm:rounded-lg md:flex md:h-full md:items-center  md:justify-center md:rounded-none md:p-10 lg:p-14 xl:w-2/5'>
          <div className='w-full max-w-md space-y-8 md:space-y-14'>
            <h1 className='text-5xl font-bold text-gray-80 mb-5'>
              Welcome To Fluentum
            </h1>
            <span className='mt-4'>Sign in to your account</span>
            <div>
              <Formik
                initialValues={formValue}
                validationSchema={validationSchema(loginForm)}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                  dirty,
                  isSubmitting,
                }: FormikProps<LoginFormDTO>) => {
                  return (
                    <Form>
                      <div className='space-y-6'>
                        <FormControl id='username-login' errorKey='username'>
                          <FormLabel>Username</FormLabel>
                          <Input
                            variant='outline'
                            color='primary'
                            name='username'
                            placeholder='Type Username here...'
                            type='text'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl id='password-login' errorKey='password'>
                          <FormLabel>Password</FormLabel>
                          <Input
                            color='primary'
                            variant='outline'
                            name='password'
                            type={'password'}
                            placeholder='Type password here...'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <div className='mt-3 mb-6 flex flex-row justify-end'>
                          <Link
                            to='/forgot_password'
                            className='text-sm text-blue-600'
                          >
                            Forgot your password ?
                          </Link>
                        </div>
                        <Button
                          type='submit'
                          variant='solid'
                          color='primary'
                          isFluid
                          disabled={!(isValid && dirty)}
                          isLoading={isSubmitting}
                        >
                          Sign in
                        </Button>
                        {/* <button
                          type="submit"
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          disabled={!(isValid && dirty)}
                        >
                          Sign in
                        </button> */}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className='relative hidden h-full flex-auto items-center justify-center overflow-hidden bg-blue-500 p-10 text-white sm:w-1/2 md:flex xl:w-3/5'>
          <img
            src={bannerImage}
            className='absolute h-full w-full object-cover'
            alt='Banner'
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
