import { Location } from 'react-router-dom';

export interface DashboardFormProps {
  type: DashboardFormType;
}

export const TypeDashboardForm = {
  view: 'view',
  create: 'create',
  edit: 'edit',
  deviasi: 'deviasi',
  amandemen: 'amandemen',
  groups: 'groups',
} as const;

export type DashboardFormType =
  (typeof TypeDashboardForm)[keyof typeof TypeDashboardForm];

export interface BuildBreadcrumbs {
  type: DashboardFormType;
  parentTitle: string;
  currentTitle: string;
  currentPath: Location;
}

export interface DashboardBaseTypeDetail {
  isEdit: boolean;
  isCreate: boolean;
  isView: boolean;
  isDeviasi: boolean;
  isAmandemen: boolean;
}
