import { Form, Formik, FormikProps } from 'formik';
import { useReset } from '../hooks';
// import { useState } from "react";
import { validationSchema } from '../../../utils/validation';
import { resetForm } from '../schema';
import { ResetFormDTO } from '../types';
import bannerImage from 'src/assets/images/3.jpeg';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from '../../../components/elements';

const ResetPassword = () => {
  const { formValue, handleSubmit } = useReset();
  //   const [showPassword, setShowPassword] = useState<boolean>(false);
  //   const toggleShowPassword = () => {
  //     setShowPassword((prev: any) => !prev);
  //   };
  return (
    <div className='relative flex min-h-screen'>
      <div className='flex min-w-0 flex-auto flex-row items-center justify-center bg-white md:items-start md:justify-start'>
        <div className='w-auto bg-white p-8 sm:rounded-lg md:flex md:h-full md:items-center md:justify-center md:rounded-none md:p-10 lg:p-14 xl:w-2/5'>
          <div className='w-full max-w-md space-y-8 md:space-y-14'>
            <h1 className='text-5xl font-bold text-gray-800 mb-5'>
              Reset Your Password
            </h1>
            <span>Please enter your new password</span>
            <div>
              <Formik
                initialValues={formValue}
                validationSchema={validationSchema(resetForm)}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                  dirty,
                  isSubmitting,
                }: FormikProps<ResetFormDTO>) => {
                  return (
                    <Form>
                      <div className='space-y-6'>
                        <FormControl errorKey='new_password'>
                          <FormLabel>New Password</FormLabel>
                          <Input
                            color='primary'
                            variant='outline'
                            name='new_password'
                            type={'password'}
                            placeholder='Type password here...'
                            value={values.new_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl errorKey='confirm_password'>
                          <FormLabel>Confirm Password</FormLabel>
                          <Input
                            color='primary'
                            variant='outline'
                            name='confirm_password'
                            type={'password'}
                            placeholder='Confirm your password here...'
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <Button
                          type='submit'
                          variant='solid'
                          color='primary'
                          isFluid
                          disabled={!(isValid && dirty)}
                          isLoading={isSubmitting}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className='relative hidden h-full flex-auto items-center justify-center overflow-hidden bg-blue-500 p-10 text-white sm:w-1/2 md:flex xl:w-3/5'>
          <img
            src={bannerImage}
            className='absolute h-full w-full object-cover'
            alt='Banner'
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
