import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

// import { PageNotFound } from '@elements/index'
// import MaintenancePage from '@elements/mistake/Maintenance'

export const AppRoutes = (): JSX.Element => {
  const element = useRoutes([
    ...protectedRoutes,
    ...publicRoutes,
    {
      path: '*',
      element: <h1>Page Not Found</h1>,
    },
    /* For Maintenance
    {
      path: '*',
      element: <MaintenancePage _message='xxx' />,
    },
    */
  ]);
  return <>{element}</>;
};

export default AppRoutes;
