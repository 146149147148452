import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { removeStoredLastCurrentPage } from '../../../utils/base';
import { NavigationItemMenuProps } from './Navigation.types';
import { NavigationSubMenu } from './NavigationSubMenu';
import { useClusterForm } from 'src/features/clusters/hooks';
import { TypeDashboardForm } from 'src/types/dashboard';
import clsx from 'clsx';

import type { DashboardFormProps } from 'src/types/dashboard';

export const NavigationMenu = forwardRef<
  HTMLLIElement,
  NavigationItemMenuProps
>((props, ref): JSX.Element => {
  const { contentData, collapsed, menuIndex, formattedAccess, ...rest } = props;
  const getType: DashboardFormProps = {
    type: TypeDashboardForm.create,
  };
  const { handleWindowClose } = useClusterForm(getType);

  if (contentData?.children) {
    return (
      <li ref={ref} {...rest}>
        <NavigationSubMenu
          menuIndex={menuIndex}
          contentData={contentData}
          collapsed={collapsed}
          formattedAccess={formattedAccess}
        />
      </li>
    );
  }
  
  return (
    <>
      {contentData?.access && (
        <li ref={ref} {...rest}>
          <div className='group mx-2'>
            <Link
              to={contentData.to}
              onClick={(e) => {
                e.preventDefault();
                removeStoredLastCurrentPage();
                handleWindowClose(contentData.to);
              }}
              className={clsx(
                'menu',
                contentData.isActive && 'active',
                !collapsed && 'flex justify-center'
              )}
            >
              <div className='flex'>
                {contentData?.icon && (
                  <contentData.icon
                    className={clsx('h-6 w-6', collapsed && 'mr-2')}
                  />
                )}
                <span
                  className={clsx(
                    collapsed ? 'opacity-100' : 'w-0 overflow-hidden opacity-0',
                    'whitespace-nowrap transition-all duration-200'
                  )}
                >
                  {contentData.title}
                </span>
              </div>
            </Link>
            {!collapsed && (
              <div className='dropdown-menu absolute left-[120%] top-0 z-50 hidden h-auto group-hover:block'>
                <ul className='top-0 w-48 bg-white shadow-md'>
                  <li>
                    <Link
                      to={contentData.to}
                      onClick={(e) => {
                        e.preventDefault();
                        removeStoredLastCurrentPage();
                        handleWindowClose(contentData.to);
                      }}
                      className={clsx(
                        'menu',
                        contentData.isActive && 'active',
                        'bg-blue-50 !pl-4'
                      )}
                    >
                      <div className='flex'>
                        <span className={clsx('whitespace-nowrap')}>
                          {contentData.title}
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </li>
      )}
    </>
  );
});

NavigationMenu.displayName = 'NavigationMenu';
