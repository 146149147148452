import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import {
  HiBadgeCheck,
  HiExclamationCircle,
  HiOutlineX,
  HiXCircle,
} from 'react-icons/hi';
import { Button } from '../elements';
import { componentConfig } from '../../constans';
import { removeConfirmation, useConfirmation } from '../../stores/confirmation';
import { mergeRefs } from '../../utils/refs';
import { ConfirmationIconType, ConfirmationType } from './Confirmation.types';
import clsx from 'clsx';

import type { ConfirmationIconTypeData } from './Confirmation.types';

const getIcon = (icon?: ConfirmationIconType): ConfirmationIconTypeData => {
  switch (icon) {
    case ConfirmationIconType.warning:
      return {
        icon: HiExclamationCircle,
        color: 'text-orange-500',
      };
    case ConfirmationIconType.success:
      return {
        icon: HiBadgeCheck,
        color: 'text-green-500',
      };
    default:
      return {
        icon: HiXCircle,
        color: 'text-red-500',
      };
  }
};

export const Confirmation = forwardRef<HTMLDivElement>((props, ref) => {
  const [confirm] = useConfirmation();
  const isOpen: boolean = confirm !== null;
  const isConfirm = confirm?.type === ConfirmationType.confirm;
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);
  const [dismiss, setDismiss] = useState({
    ...componentConfig.confirmation.dismiss,
    ...(confirm?.dismiss || {}),
  });

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: () => {
      removeConfirmation();
    },
  });

  useEffect(() => {
    setDismiss((prev) => ({
      ...prev,
      enabled: false,
    }));
  }, [isConfirmLoading, isRejectLoading]);

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, dismiss),
  ]);

  const icons = getIcon(confirm?.icon);
  const defaultConfirm = isConfirm ? 'Yes' : 'OK';
  const defaultReject =
    confirm?.icon === ConfirmationIconType.success ||
    confirm?.icon === ConfirmationIconType.error
      ? 'OK'
      : 'No';
  const disabled = isRejectLoading || isConfirmLoading;

  const mergedRef = useMemo(() => mergeRefs([ref, refs.floating]), [refs, ref]);

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay
          style={{
            zIndex: 49,
          }}
          lockScroll
        >
          <FloatingFocusManager context={context}>
            <div className='fixed inset-0 z-50 flex h-full w-full items-center justify-center'>
              <div
                className={clsx(
                  'fixed inset-0 h-full w-full bg-gray-900 bg-opacity-20',
                  dismiss?.enabled && dismiss?.outsidePress && 'cursor-pointer'
                )}
                aria-hidden='true'
              />
              <div
                {...getFloatingProps({
                  ...props,
                  ref: mergedRef,
                  className:
                    'relative z-50 w-full max-w-md rounded-xl bg-white shadow',
                })}
              >
                {dismiss?.closeButton && (
                  <Button
                    type='button'
                    variant='outline'
                    className='close'
                    disabled={disabled}
                    onClick={removeConfirmation}
                  >
                    <HiOutlineX className='h-5 w-5 text-gray-800' />
                  </Button>
                )}
                <div className='py-8 px-12 text-center'>
                  <icons.icon
                    className={clsx('mx-auto mb-2 h-16 w-16', icons.color)}
                  />
                  <div>
                    <h1 className='whitespace-pre-wrap text-2xl font-semibold text-gray-800'>
                      {confirm?.title}
                    </h1>
                    <p className='mt-1 text-base leading-tight text-gray-500 break-words'>
                      {confirm?.message}
                    </p>
                  </div>
                  <div className='mt-10 flex gap-4'>
                    <div className='w-full'>
                      <Button
                        variant={isConfirm ? 'outline' : 'solid'}
                        color={!isConfirm ? 'primary' : ''}
                        type='button'
                        onClick={() => {
                          confirm?.onReject?.(
                            removeConfirmation,
                            setIsRejectLoading
                          );
                        }}
                        leftIcon={confirm?.reject?.leftIcon}
                        rightIcon={confirm?.reject?.rightIcon}
                        disabled={disabled}
                        isLoading={isRejectLoading}
                        isFluid
                        id='button-onreject'
                      >
                        {confirm?.reject?.text || defaultReject}
                      </Button>
                    </div>
                    {isConfirm && (
                      <div className='w-full'>
                        <Button
                          type='button'
                          variant='solid'
                          color='primary'
                          onClick={() => {
                            confirm?.onConfirm?.(
                              removeConfirmation,
                              setIsConfirmLoading
                            );
                          }}
                          leftIcon={confirm?.confirm?.leftIcon}
                          rightIcon={confirm?.confirm?.rightIcon}
                          disabled={disabled}
                          isLoading={isConfirmLoading}
                          id='button-onconfirm'
                          isFluid
                        >
                          {confirm?.confirm?.text || defaultConfirm}
                        </Button>
                      </div>
                    )}
                  </div>
                  {confirm?.footer && (
                    <div className='mt-3'>{confirm?.footer}</div>
                  )}
                </div>
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
});

Confirmation.displayName = 'Confirmation';
