import { BiShapeTriangle, BiAnalyse } from 'react-icons/bi';
import { BsGraphUp, BsArrowLeftRight, BsFillGearFill } from 'react-icons/bs';
import { FaKey, FaCubes, FaAlignLeft } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa6';
import { FiFilter, FiLogOut } from 'react-icons/fi';
import { HiBellAlert } from 'react-icons/hi2';
import { MdOutlineSettingsInputComponent } from 'react-icons/md';

const navigation = [
  {
    title: 'Overview',
    to: '',
    icon: BsGraphUp,
    access: ['*', 'dashboard'],
    installed: true,
  },
  {
    title: 'Alerts',
    to: '/alerts',
    icon: HiBellAlert,
    access: ['*', 'alerts'],
    installed: true,
  },
  {
    title: 'API Keys',
    to: '/keys',
    icon: FaKey,
    access: ['*', 'apikeys'],
    installed: true,
  },
  {
    title: 'Brokers',
    to: '/brokers',
    icon: FaCubes,
    access: ['*', 'brokers'],
    installed: true,
  },
  {
    title: 'Topics',
    to: '/topics',
    icon: FiLogOut,
    access: ['*', 'topics'],
    installed: true,
  },
  {
    title: 'Consumer Groups',
    to: '/groups',
    icon: FiFilter,
    access: ['*', 'consumer-groups'],
    installed: true,
  },
  {
    title: 'Connectors',
    to: '/connectors',
    icon: BiShapeTriangle,
    access: ['*', 'connectors'],
    installed: true,
  },
  {
    title: 'Client Code',
    to: '/clients',
    icon: BsArrowLeftRight,
    access: ['*', 'clients'],
    installed: true,
  },
  {
    title: 'REST Proxy',
    to: '/proxies',
    icon: FaServer,
    access: ['*', 'rest-proxy'],
    installed: true,
  },
  {
    title: 'Request Logs',
    to: '/logs',
    icon: FaAlignLeft,
    access: ['*', 'logs'],
    installed: true,
  },
  {
    title: 'Rebalance',
    to: '/rebalance',
    icon: BiAnalyse,
    access: ['*', 'cruisecontrol'],
    installed: true,
  },
  {
    title: 'Components',
    to: '/components',
    icon: MdOutlineSettingsInputComponent,
    access: ['*', 'components'],
    installed: true,
  },
  {
    title: 'Settings',
    to: '/settings',
    icon: BsFillGearFill,
    access: ['*', 'base'],
    installed: false,
  },
];

export { navigation };
