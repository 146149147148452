import clsx from 'clsx';

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelFor: string;
  inputClass?: string;
  labelClass?: string;
  disabled?: boolean;
}

export const RadioInput: React.FC<RadioInputProps> = (props) => {
  const { label, labelFor, inputClass, labelClass, disabled, ...rest } = props;

  return (
    <>
      <input
        type='radio'
        className={clsx('hidden peer', inputClass)}
        disabled={disabled}
        {...rest}
      />
      <label
        htmlFor={labelFor}
        className={clsx(
          'w-full flex items-center justify-center p-1 text-gray-500 border border-gray-200 peer-checked:border-blue-600 peer-checked:text-blue-600',
          disabled
            ? 'bg-gray-100'
            : 'cursor-pointer hover:text-gray-600 hover:bg-gray-100',
          labelClass
        )}
      >
        {label}
      </label>
    </>
  );
};

RadioInput.displayName = 'RadioInput';
