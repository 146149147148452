import { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { emptyValueLabel } from '../../../../utils/components';
import { DropdownIndicator, selectStyles } from './Select';
import { getValueKey } from '../../../../utils/helper';
import clsx from 'clsx';

import type { BaseApis } from '../../../../types/apis';
import type { SelectPaginationProps } from './SelectPagination.types';

export const SelectPagination = <T extends BaseApis<any>, U, V>(
  props: SelectPaginationProps<T, U, V>
) => {
  const {
    className,
    perPage = 10,
    debounceTimeout = 1000,
    searchKey = 'search',
    valueKey = 'id',
    labelKey = 'nama',
    isAllData = false,
    apiController,
    query,
    cacheUniqs,
    value,
    idApi,
    ...rest
  } = props;

  const fetchApi = apiController();
  const [search, setSearch] = useState<string>('');
  const queryData = {
    ...(searchKey !== '' && search !== '' ? { [searchKey]: search } : {}),
    ...query,
  };

  const loadOptions = async (search: string, prev: any, more: any) => {
    setSearch(search);
    const page = more?.page || 1;
    const getData = await fetchApi
      .mutateAsync({
        id: idApi,
        params: {
          limit: perPage,
          page,
          ...(searchKey !== '' && search !== '' ? { [searchKey]: search } : {}),
          ...query,
        },
      } as V)
      .catch(() => {
        return {} as BaseApis<any>;
      });

    const totalData = getData?.metadata?.totalData || 0;
    const resultData = (getData?.data || []).map((val: any) => {
      return {
        ...(isAllData ? val : {}),
        value: getValueKey(valueKey, val),
        label: getValueKey(labelKey, val),
      };
    });

    return {
      options: resultData,
      hasMore: totalData > perPage * page,
      additional: {
        page: page + 1,
      },
    };
  };

  const cacheKeys: ReadonlyArray<any> = cacheUniqs || Object.values(queryData);
  const newValue = emptyValueLabel(value);
  return (
    <AsyncPaginate
      id='react-select-listbox'
      value={newValue}
      loadOptions={loadOptions}
      components={{ DropdownIndicator }}
      styles={selectStyles}
      debounceTimeout={debounceTimeout}
      className={clsx('w-full', className)}
      cacheUniqs={cacheKeys}
      {...rest}
    />
  );
};

export default SelectPagination;
