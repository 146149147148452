import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../components/elements/loading-screen';
import { setAuth, useAuth } from '../stores/auth';
import { AuthServices, AuthTypes } from '../features/auth';
import { validAuth } from '../utils/base';
import { storage } from '../utils/storage';
import { storageKey } from '../constans';
import axios from 'axios';

import type { UseQueryResult } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { BaseApis } from '../types/apis';
import type { BaseProviderProps } from '../types/components';

const GlobalAuthProvider: React.FC<BaseProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const hasValidAuth = validAuth().isValid;

  const getUserMe = AuthServices.useUserMe({
    config: {
      enabled: false,
    },
  }) as UseQueryResult<AuthTypes.UserMeDTO, AxiosError<BaseApis<null>>>;

  useEffect(() => {
    if (hasValidAuth && !auth) getUserMe.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!getUserMe.data) return;

    const userMe = getUserMe?.data;
    if (!userMe) {
      console.log('Terdapat kesalahan saat mengambil user info');
      storage.removeItem(storageKey.authToken);
      navigate('/login');
    } else {
      setAuth(userMe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserMe.data]);

  useEffect(() => {
    if (!hasValidAuth || !auth || !auth.permission) return;

    const subPath = location.pathname.split('/');
    if (subPath[2] === 'profile') return;
    if (subPath[2] === 'admin') {
      const path = subPath[3].split('-').join('');
      if (!auth.permission.includes(path)) navigate('/dashboard/clusters');
    } else {
      if (!auth.permission.includes(subPath[2]))
        navigate('/dashboard/clusters');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location.pathname]);

  if ((getUserMe.isSuccess && hasValidAuth) || !hasValidAuth) {
    return <>{children}</>;
  }

  if (getUserMe.isLoading && hasValidAuth) {
    return <LoadingScreen reason='Get user info' />;
  }

  if (
    getUserMe.error &&
    getUserMe.error.code === axios.AxiosError.ERR_BAD_REQUEST
  ) {
    storage.removeItem(storageKey.authToken);
    return <>{children}</>;
  }

  if (getUserMe.isError) {
    return (
      //   <DetailError
      //     error={getUserMe.error}
      //     isReload
      //     customAction={<ButtonRefresh />}
      //   />
      <h1>error get user</h1>
    );
  }

  return (
    // <Mistake
    //   code='Opps'
    //   title='Something went wrong :('
    //   subtitle={`Unhandled status: ${getUserMe.status}`}
    // >
    //   <div className='mt-6'>
    //     <ButtonRefresh />
    //   </div>
    // </Mistake>
    <h1>Something went wrong :(</h1>
  );
};

export default GlobalAuthProvider;
