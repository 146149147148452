import { useState } from 'react';

export const useEmbedLoader = () => {
  let [loading, setLoading] = useState<any>(true)

  setTimeout(() => {
    setLoading(false);
  }, 2000)

  return {
    loading
  }

};
