import { emptyValueLabel } from '../../../../utils/components';
import { HiOutlineChevronUpDown } from 'react-icons/hi2';
import clsx from 'clsx';
import ReactSelect from 'react-select';

import type { CSSObjectWithLabel, Props, StylesConfig } from 'react-select';

const selectStyles: StylesConfig = {
  option: (style, { isDisabled, isFocused, isSelected }) => {
    const getBackgroundColor = () => {
      if (isDisabled) return undefined;
      if (isSelected) return '#3b82f6';
      if (isFocused) return '#dbeafe';
      return undefined;
    };

    return {
      ...style,
      color: isSelected ? '#fff' : style.color,
      backgroundColor: getBackgroundColor(),
      '&:hover': {
        color: '#000',
        backgroundColor: '#dbeafe',
      },
      '&:active': {
        color: '#000',
        backgroundColor: '#93c5fd',
      },
    } as CSSObjectWithLabel;
  },
  control: (style, { isFocused }) =>
    ({
      ...style,
      boxShadow: isFocused ? '0 0 0 1px #3b82f6' : '',
      borderColor: isFocused ? '#3b82f6' : '#CED4DA',
      '&:hover': {
        borderColor: isFocused ? '#3b82f6' : '#CED4DA',
      },
    }) as CSSObjectWithLabel,
  multiValue: (style) => {
    return {
      ...style,
      backgroundColor: '#3b82f6',
    } as CSSObjectWithLabel;
  },
  multiValueLabel: (style) =>
    ({
      ...style,
      color: '#fff',
    }) as CSSObjectWithLabel,
  multiValueRemove: (style) =>
    ({
      ...style,
      color: '#fff',
      ':hover': {
        backgroundColor: '#2563eb',
        color: '#fff',
      },
    }) as CSSObjectWithLabel,
};

const DropdownIndicator = () => {
  return (
    <div className='px-2'>
      <HiOutlineChevronUpDown className='h-5 w-5 text-gray-500' />
    </div>
  );
};

const Select: React.FC<Props> = (props) => {
  const { className, value, isDisabled, ...rest } = props;

  const newValue = emptyValueLabel(value);

  return (
    <ReactSelect
      value={newValue}
      components={{ DropdownIndicator }}
      styles={selectStyles}
      className={clsx('w-full rounded-md', className)}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

export default Select;

export { DropdownIndicator, Select, selectStyles };
