import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../stores/auth';
import { Button, Navigation } from '../../elements';
import { navigation } from './data';
import { DashboardContext } from '../Dashboard/ContextProvider';
import { ClusterDetailContext } from './ContextProvider';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';

import type { BaseLayoutProps } from '../../../types/components';

const ClusterDetailLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth] = useAuth();

  const [currentPath, setCurrentPath] = useState<string>('');
  const [collapseSubMenu, setCollapseSubMenu] = useState(true);
  const [clusterMenu, setClusterMenu] = useState([]);

  const { handleCollapseSideMenu } = useContext(DashboardContext);
  const { clusterData } = useContext(ClusterDetailContext);

  const formattedAccess = useMemo((): string[] => {
    const permissionData =
      auth?.user?.orgs[0].permissions.reduce((filtered: string[], option) => {
        const readPermission = option.split(':');
        if (readPermission[0] === 'read' && readPermission[1] === 'clusters') {
          if (readPermission[3]) filtered.push(readPermission[3]);
          else if (readPermission[2] === '*') filtered.push('*');
        }
        return filtered;
      }, []) || [];
    return [...new Set(permissionData)];
  }, [auth?.user?.orgs]);

  useEffect(() => {
    handleCollapseSideMenu(false);
  }, [handleCollapseSideMenu]);

  useEffect(() => {
    if (clusterData.title && !clusterData.installed) {
      const currentLocation = location.pathname.split('/');
      if (currentLocation[currentLocation.length - 1] !== 'settings') {
        const path = currentLocation.slice(0, 5).join('/') + '/settings';
        navigate(path);
      }
    }
  }, [clusterData, location, navigate]);

  useEffect(() => {
    if (clusterData.title) {
      const filterMenu = navigation.filter(
        (value) =>
          !value.installed || (value.installed && clusterData.installed)
      );
      setClusterMenu(filterMenu as any);
    }
  }, [clusterData]);

  useEffect(() => {
    const currentLocation = location.pathname.split('/');
    setCurrentPath(currentLocation.slice(0, 5).join('/'));
  }, [location.pathname]);

  useEffect(() => {
    if (!formattedAccess || formattedAccess.length < 1) return;

    const currentLocation = location.pathname.split('/');
    const menuData = navigation.find(
      (item) => item.to === (currentLocation[5] ? `/${currentLocation[5]}` : '')
    );
    const hasAccess =
      menuData?.access.some((data) => formattedAccess.includes(data)) || false;
    if (!hasAccess) {
      if (navigation[0].access.some((data) => formattedAccess.includes(data))) {
        navigate(currentLocation.slice(0, 5).join('/'));
      } else {
        navigate(currentLocation.slice(0, 5).join('/') + '/topics');
      }
    }
  }, [formattedAccess, location.pathname, navigate]);

  return (
    <div className='h-full w-full'>
      <div className='h-full relative flex overflow-hidden'>
        <div
          className={clsx(
            'mini-scrollbar fixed z-[1] h-screen flex flex-col bg-white top-0 py-4 pt-20',
            'border border-gray-200 overflow-y-auto transition-all duration-100',
            collapseSubMenu ? 'w-52' : 'w-20'
          )}
        >
          <div className='flex justify-between items-center border-b border-gray-200'>
            {collapseSubMenu && (
              <div className='py-2 px-3'>
                <h4 className='mb-2 text-base font-thin leading-tight text-neutral-800 '>
                  cluster
                </h4>
                <h5 className='mb-2 text-lg font-medium leading-tight text-neutral-800 '>
                  {clusterData.title || '-'}
                </h5>
              </div>
            )}

            <div
              className={clsx(
                'm-3',
                !collapseSubMenu && 'flex justify-center w-full'
              )}
            >
              <Button
                type='button'
                variant='ghost'
                className='!px-1'
                onClick={() => setCollapseSubMenu((prevState) => !prevState)}
              >
                {collapseSubMenu ? (
                  <HiOutlineChevronLeft className='h-5 w-5' />
                ) : (
                  <HiOutlineChevronRight className='h-5 w-5' />
                )}
              </Button>
            </div>
          </div>
          {currentPath && clusterMenu.length > 0 ? (
            <div className='my-3'>
              <Navigation
                id='cluster-detail-navigation'
                currentLocation={location.pathname}
                access={formattedAccess}
                currentPath={currentPath}
                items={clusterMenu}
                collapsed={collapseSubMenu}
              />
            </div>
          ) : (
            <div className='p-1'>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} className='h-10 w-full bg-slate-200' />
              ))}
            </div>
          )}
        </div>
        <div
          className={clsx(
            'relative ml-0 h-full w-full overflow-y-auto bg-gray-100 transition-all duration-100',
            collapseSubMenu ? 'ml-52' : 'ml-20'
          )}
        >
          <main className='h-full'>{children}</main>
        </div>
      </div>
    </div>
  );
};

export default ClusterDetailLayout;
